import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { useVisits } from "../common/useVisits";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import {
  setMileageClaimVisit,
  setReceiptClaimVisit,
} from "../redux/slices/claimsSlice";
import { RootState, store } from "../redux/store";
import { Visit, dateString, isFutureDate } from "../types/common";
import CardContainer from "./CardContainer";

function ClaimVisitsPage() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const visitId = useSelector((state: RootState) => {
    if (id === "receipt") {
      return state.claims?.receiptClaim?.visit_id
    } else {
      return state.claims?.mileageClaim?.visit_id
    }
  });

  const hideVisitMetaData = store.getState().auth.profile?.hide_visits_meta_info;

  usePageTitle(id === "receipt" ? t("claim_chooseClaimReceipt") : t("claim_chooseClaimMileage"));

  const [value, setValue] = useState<string>(visitId?.toString() || "");

  const dispatch = useDispatch();
  const { visits, isLoading } = useVisits();

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onContinue = () => {
    if (!value) return;
    if (id === "receipt") {
      dispatch(setReceiptClaimVisit(parseInt(value)));
      navigate("/payment/submit/receipt/categories");
    } else if (id === "mileage") {
      dispatch(setMileageClaimVisit(parseInt(value)));
      navigate("/payment/submit/mileage/distance");
    }
  };

  return (
    <CardContainer
      title={t("claimDetail_relatedVisit")}
      subtitle={`${id === "receipt"
        ? t("receiptClaim_visits_description")
        : t("mileagesVisits_HeaderSubTitle")
        }. ${t("mileagesVisits_pastVisitsNote")}`}
      topRightContent={id === "receipt" ? <ReceiptImagePreview /> : null}
    >
      <form>
        <FormControl component="fieldset" fullWidth>
          {isLoading ? (
            Array.from(new Array(3)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={56}
                sx={{ borderRadius: 1, mb: 1 }}
              />
            ))
          ) : (
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {visits.map((visit: Visit) => (
                <FormControlLabel
                  disabled={isFutureDate(visit.earliest_date)}
                  key={visit.id.toString()}
                  value={visit.id.toString()}
                  control={<Radio sx={hideVisitMetaData ? {} : { pt: 0 }} />}
                  label={
                    <Stack direction="column">
                      <Typography data-test-id="visit-name" variant="body1">
                        {visit.name}
                      </Typography>
                      <Typography
                        data-test-id="visit-date"
                        variant="subtitle1"
                        color="textSecondary"
                      >
                        {dateString(visit)}
                      </Typography>
                    </Stack>
                  }
                  sx={{ my: 1 }}
                />
              ))}
            </RadioGroup>
          )}
        </FormControl>

        <Button
          sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
          disabled={!value}
          onClick={onContinue}
          data-test-id="submit-button"
        >
          {t("continue_text")}
        </Button>
      </form>
    </CardContainer >
  );
}

export default ClaimVisitsPage;
